import { timeFormat, numberFormat } from "@custom/index";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 80,
    },
    {
        prop: "title",
        label: "公告标题",
    },
    {
        prop: "publisher",
        label: "内容发布人",
        width: 100
    },
    {
        label: "内容时间",
        render: (h, {row}) => {
            return row.publishTime ? timeFormat(new Date(row.publishTime),"yyyy-MM-dd HH:mm:ss") : "";
        }
    },
    {
        prop: "statusName",
        label: "状态",
    },
];