import request from '@http';

// 2022/06/28 王江毅 商铺列表 商圈/商铺列表
export function getShopList(params) {
    return request({
        method: 'GET',
        url: '/api/shop/community/list',
        params
    });
}

// 2022/06/29 王江毅 添加商圈
export function communityAdd(data) {
    return request({
        method: 'POST',
        url: '/api/shop/community/add',
        data
    });
}

// 2022/06/29 王江毅 修改商圈
export function communityUpdate(data) {
    return request({
        method: 'PUT',
        url: '/api/shop/community/update',
        data
    });
}

// 2022/06/29 王江毅 添加商铺
export function apartmentAdd(data) {
    return request({
        method: 'POST',
        url: '/api/shop/apartment/add',
        data
    });
}

// 2022/06/29 王江毅 修改商铺
export function apartmentUpdate(data) {
    return request({
        method: 'PUT',
        url: '/api/shop/apartment/update',
        data
    });
}
// 2022/06/29 王江毅 商铺出租 商铺列表
export function getApartmentPage(params) {
    return request({
        method: 'GET',
        url: '/api/shop/apartment/page',
        params
    });
}