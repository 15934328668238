<template>
    <section class="menu-management padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.comment" placeholder="请输入角色名搜索" style="margin-right: 10px;width: 250px" size="small"/>
            <el-input v-model="formSearch.name" placeholder="请输入备注搜索" style="margin-right: 10px;width: 250px" size="small"/>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="saveRole('add', null)" size="small">
                新增角色
            </el-button>
        </div>
        <r-e-table class="bg-white" ref="roleTableRef" :dataRequest="getRoleList" :columns="tableColumn" :query="formSearch" :height="840">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="saveRole('edit', row)">编辑</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <dialog-save-role ref="dialogSaveRole" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {getRoleList} from "@/api/role";
    import {tableColumn} from "@/views/system-management/role-management/data";

    export default {
        name: "menu-management",
        data() {
            return {
                tableColumn,
                formSearch: {}
            };
        },
        components: {
            dialogSaveRole: () => import("./components/dialog-save-role"),
        },
        methods: {
            getRoleList(params) {
                return getRoleList(params);
            },
            handleSearch() {
                this.$refs['roleTableRef'].pNumber = 1;
                this.$refs['roleTableRef'].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            saveRole(type, data) {
                this.$refs["dialogSaveRole"].openDialog(type, data);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-management {

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: left;

            .addDictionary {
                position: absolute;
                left: VW(40px);
            }
        }
    }
</style>