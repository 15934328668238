import request from '@http';

// 2022/06/15 王江毅 菜单列表树形结构
export function getMenuTree(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/system/menu/tree`,
        params
    })
}

// 2022/06/15 王江毅 添加菜单/页面/按钮权限
export function menuSave(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/system/menu/save`,
        data
    })
}

// 2022/06/15 王江毅 修改菜单/页面/按钮权限
export function menuUpdate(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/system/menu/update`,
        data
    })
}