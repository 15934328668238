import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    {
        prop: "comment",
        label: "角色名"
    },
    {
        prop: "name",
        label: "备注"
    }
];