import {timeFormat,numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    /*{
        prop: "title",
        label: "消息",
        showOverflowTooltip: true
    },*/
    {
        prop: "type",
        label: "模块",
        width: 100
    },
    {
        prop: "timestamp",
        label: "提醒时间",
        width: 200,
        render: (h, {row}) => {
            const timeStamp = !row.timestamp ? "" : timeFormat(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }
    },
    /*{
        prop: "operation",
        label: "操作"
    },
    {
        prop: "recordType",
        label: "记录类型"
    },
    {
        prop: "record",
        label: "操作记录"
    }*/
];

