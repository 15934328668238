<template>
    <section class="shops-lease flex justify-between">
        <div class="form-search">
            <el-form :model="formSearch" inline size="small">
                <el-form-item>
                    <el-input v-model="formSearch.keyword" placeholder="小区名/房间名/租客名/手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">全局查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="community-tree-container bg-white">
            <r-e-tree treeName="商圈" ref="treeRef" :data="businessDistrictData" expand-on-click-node
                      :node-label="createNodeLabel" :nodeIcon="nodeIcon" @node-click="handleClickCommunity"/>
        </div>

        <div class="house-resource-container">
            <div class="card-search-content flex align-center">
                <div class="house-lease-status house-status flex bg-white">
                    <div class="item flex align-center justify-center" v-for="item in shopsStatus" :key="item.code"
                         :class="{ 'is-active-tab': activeShopsTab === item.code }"
                         @click="handleShopsStatus(item)"
                    >
                        <div class="label">{{ item.codeName }}</div>
                        <div class="value">{{ item.value || 0 }}</div>
                    </div>
                </div>
            </div>
            <div class="house-node-content flex align-center">
                <div class="node-name">{{ currentNode.name || "--" }}</div>
                <div class="floor-number">【 {{ floorNumber.label }}</div>
                <div class="house-total">{{ page.totalNum }} 间 】</div>
                <el-button v-if="canAddHouse" type="primary" icon="el-icon-plus" size="mini"
                           @click="openLayerShopsListDialog">
                    立即签约
                </el-button>
            </div>
            <template v-if="shopsList.length > 0">
                <div class="house-content hidden-scrollbar">
                    <card-shops :data="shopsList" @on-click="handleClickHouse" @release-shops="releaseShops"/>
                </div>
                <el-pagination
                        class="text-right" :current-page="page.page" :total="page.totalNum" :pager-count="7"
                        layout="total, sizes, prev, pager, next, jumper" :page-size="page.pageSize"
                        :page-sizes="[10, 50, 100, 1000, 10000]" @size-change="sizeChange"
                        @current-change="pageChange"/>
            </template>
            <el-empty style="margin-top: 100px" v-else :image-size="200"></el-empty>
        </div>

        <div class="floor-container">
            <div class="floor-name text-center bg-white" @click="handleFloor(0)">楼层</div>
            <div class="floor-content bg-white hidden-scrollbar">
                <div class="floor text-center" v-for="item in layerMax" :key="item"
                     :class="{ 'is-active-floor': item === activeFloor }" @click="handleFloor(item)">
                    {{ layerMax + 1 - item }}F
                </div>
            </div>
        </div>

        <!--发布商铺弹窗-->
        <layer-release-shops ref="layerReleaseShops"/>

        <!--房源详情抽屉弹窗-->
        <drawer-shops-info ref="drawerShopsInfo"/>
        <!--签约多个商铺弹窗-->
        <layer-shops-list-dialog ref="layerShopsListDialog" @submit="openAddContract"/>

        <layer-add-shops-contract ref="layerAddShopsContract" />
    </section>
</template>

<script>
    import {getHouseBycountApi} from "@/api/house-resource";
    import {getShopList, getApartmentPage} from '@/api/shops-list';
    export default {
        name: "shops-lease",
        data() {
            return {
                formSearch: {
                    keyword: "",
                },
                shopsStatus: [],
                activeShopsTab: "",
                activeFloor: 0,
                layerMax: 34,
                searchType: "node",
                shopsList: [],
                page: {
                    pageSize: 50,
                    page: 1,
                    totalNum: 0,
                },
                currentNode: {},
                state: 0,
                businessDistrictData: [],
            };
        },
        components: {
            cardShops: () => import("./components/card-shops"),
            drawerShopsInfo: () => import("./components/drawer-shops-info"),
            layerReleaseShops: () => import("./components/layer-release-shops"),
            layerShopsListDialog: () => import("./components/part/components/layer-shops-list-dialog"),
            layerAddShopsContract: () => import("./components/part/components/layer-add-shops-contract"),
        },
        computed: {
            canAddHouse() {
                // 判断当前节点是否可以添加房源；1，已选节点；2，当前节点是楼幢且没有单元或当前节点是单元；
                const currentNode = this.currentNode;
                return JSON.stringify(currentNode) !== "{}";
            },
            floorNumber() {
                const number = this.activeFloor ? this.layerMax + 1 - this.activeFloor : 0;
                return {
                    label: number ? `${number}F` : "全部楼层",
                    value: number
                };
            }
        },
        async created() {
            // 房间状态筛选 600310(暂时不使用)，房源管理 600000
            this.shopsStatus = await this.$store.dispatch("app/getDictionaryByCodeActions", "600000");
            await this.getShopList();
        },
        methods: {
            async handleSearch() {
                // 全局搜索房源
                this.searchType = "global";
                this.currentNode = {name: "全部小区"};
                await this.getCurrentNodeHouseList();
            },
            handleReset() {
                // 重置全局搜索
                this.formSearch = {
                    keyword: "",
                    filterStatusCode: [],
                    contractStatusCode: "",
                    leaseModeCode: "",
                };
                this.searchType = "global";
                this.currentNode = {name: "全部小区"};
                this.state = 0;
                this.activeShopsTab = '';
                this.getCurrentNodeHouseList();
            },
            handleShopsStatus(item) {
                this.activeShopsTab = item.code;
                this.state = item.code;
                this.getCurrentNodeHouseList();
            },
            handleClickCommunity(data) {
                // 点击小区树节点，获取数据
                this.currentNode = data;
                // 获取当前节点（小区/单元/楼幢）的房源列表;
                this.searchType = "node";
                this.layerMax = data.shopMaxFloorNumber || 34;
                this.activeFloor = 0;
                this.getCurrentNodeHouseList();
            },
            async getCurrentNodeHouseList() {
                try {
                    // searchType搜索类型
                    // global获取全部的房源列表，并全局搜索使用form的keyword;
                    // node点击树节点获取当前节点（小区/单元/楼幢）的房源列表;
                    // 楼层
                    const floorNumber = this.floorNumber.value;
                    // 小区名
                    const keyword = this.currentNode.name;
                    // 状态 数据字典600000下状态
                    const state = this.state
                    // 打包搜索数据
                    const formData = {...this.formSearch, floorNumber, state, whole: 0};
                    // 添加搜索框数据
                    formData.keyword = this.searchType === "node" ? keyword : formData.keyword;
                    // 打包分页数据
                    const pageParams = {...this.page};
                    // 删除总条数数据
                    delete pageParams.totalNum;
                    // 请求数据
                    const {page, list = []} = await getApartmentPage({...formData, ...pageParams});

                    const {info: {vacancy, reserve, rent, backRent, expire, maintain, clean}} = await getHouseBycountApi({...formData});

                    for (let item of this.shopsStatus) {
                        if (item.code === 600001) item.value = vacancy
                        if (item.code === 600002) item.value = reserve
                        if (item.code === 600003) item.value = rent
                        if (item.code === 600004) item.value = backRent
                        if (item.code === 600005) item.value = expire
                        if (item.code === 600006) item.value = maintain
                        if (item.code === 600007) item.value = clean
                    }
                    this.page.totalNum = page.totalNum;
                    this.shopsList = list;
                    this.page = page;
                } catch (e) {
                    console.error("获取房源列表", e);
                }
            },
            handleFloor(item) {
                // 楼层筛选
                this.activeFloor = item;
                this.getCurrentNodeHouseList();
            },
            releaseShops(data) {
                // 打开发布商铺弹窗
                this.$refs["layerReleaseShops"].openDialog(data);
            },
            handleClickHouse(data) {
                // 点击房源打开右侧房源详情抽屉层
                this.$refs["drawerShopsInfo"].openDrawer(data);
            },
            sizeChange(value) {
                // pageSize 改变时会触发
                this.page.pageSize = value;
                this.getCurrentNodeHouseList();
            },
            pageChange(value) {
                // currentPage 改变时会触发
                this.page.page = value;
                this.getCurrentNodeHouseList();
            },
            createNodeLabel(h, node) {
                // 生成tree节点，房源数量
                return h(
                    "span",
                    {
                        class: "bound-count",
                        style: {
                            color: "#2688F6",
                            fontWeight: "bold"
                        }
                    },
                    node.data.apartmentCount
                );

            },

            nodeIcon(h, node, data) {
                return h("i",
                    {
                        class: 'el-icon-office-building',
                        style: {
                            fontSize: '16px',
                            marginRight: '5px',
                            color: 'red',
                        }
                    },
                );
            },

            async getShopList(){
                let {list = [] } = await getShopList();
                this.businessDistrictData = list;
                this.$nextTick(() => {
                    this.$refs['treeRef'].setDefaultCurrentNodeKey(this.businessDistrictData[0].uuid);
                    this.handleClickCommunity(this.businessDistrictData[0]);
                });
            },

            openLayerShopsListDialog(){
                const {uuid} = this.currentNode;
                this.$refs["layerShopsListDialog"].openDialog({uuid});
            },
            openAddContract(data){
                this.$refs["layerAddShopsContract"].openDialog(data);
            }
        },
        mounted() {
            // 楼层选择器滚动到最底部
            const floorContent = document.querySelector(".floor-content");
            floorContent.scrollTop = floorContent.scrollHeight;

        }
    }
</script>

<style lang="scss" scoped>
    .shops-lease {
        height: VH(980px);

        .form-search {
            position: fixed;
            top: VH(30px);
            left: VW(550px);
        }

        .community-tree-container {
            width: VW(300px);
            height: 100%;
            box-shadow: 6px 0 12px rgba(6, 92, 136, 0.16);
        }

        .house-resource-container {
            width: VW(1280px);
            padding: VH(20px) 0;

            .card-search-content {
                padding: 0 0 VH(50px) VW(20px);

                .house-lease-status {
                    border-radius: 4px;
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                    &:first-child {
                        margin-right: VW(50px);
                    }

                    .item {
                        color: #666;
                        padding: VH(13px) VW(30px);
                        font-size: rem(15px);
                        cursor: pointer;
                        font-family: PingFang SC Regular;

                        .value {
                            color: #61A7FB;
                            padding-left: VW(5px);
                        }

                        &.is-active-tab,
                        &:hover {
                            color: #fff;
                            background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);

                            .value {
                                color: #fff;
                            }
                        }
                    }
                }

            }

            .house-node-content {
                font-size: rem(20px);
                font-weight: bold;
                padding: 0 0 VH(30px) VW(20px);

                .floor-number {
                    color: #2A8AF7;
                    padding: 0 VW(10px);
                }

                .house-total {
                    color: #2A8AF7;
                }
            }

            .house-content {
                height: VH(730px);
            }

            .el-pagination {
                margin: VH(20px) VW(20px);
            }
        }

        .floor-container {
            width: VW(90px);
            margin: VH(40px) VW(20px) VH(20px) 0;
            font-family: PingFang SC Regular;

            .floor-name {
                color: #333;
                height: VH(76px);
                line-height: VH(76px);
                font-size: rem(27px);
                font-weight: 400;
                border-bottom: 1px solid rgba(6, 92, 136, 0.16);
                border-radius: 6px 6px 0 0;
                cursor: pointer;

                &:hover {
                    color: #2A8AF7;
                }
            }

            .floor-content {
                padding-top: VH(15px);
                max-height: VH(840px);

                .floor {
                    height: VH(50px);
                    color: #999;
                    font-size: rem(20px);
                    line-height: VH(50px);
                    cursor: pointer;

                    &.is-active-floor,
                    &:hover {
                        color: #fff;
                        background: linear-gradient(106deg, #68ACFD 0%, #2A80E8 100%);
                    }
                }
            }
        }
    }
</style>
