<template>
    <section class="shops-list">
        <div class="form-search-container bg-white text-left">
            <el-form ref="searchForm" :model="formSearch" inline size="small" :rules="{}">
                <el-form-item label="区域" prop="province">
                    <el-select v-model="formSearch.province" placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name"
                                   :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="city">
                    <el-select v-model="formSearch.city" placeholder="请选择市" @change="cityChange">
                        <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="district">
                    <el-select v-model="formSearch.district" placeholder="请选择区">
                        <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="name">
                    <el-input v-model="formSearch.name" placeholder="请输入商圈名称"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
                <el-button type="primary" icon="el-icon-plus" size="small"
                           @click="addEditBusinessDistrict('add', null)">
                    添加商圈
                </el-button>
            </el-form>
        </div>

        <div class="table-container bg-white">
            <r-e-table ref="tableRef" :dataRequest="getShopList" :columns="tableColumn" :query="formSearch"
                       :height="840" :show-pagination="false" :afterDataReq="afterDataReq">
                <el-table-column slot="toolbar" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="editHandle('edit', row)">编辑</span>
                            <span v-if="row.children" class="table-btn" @click="addEditShops('add', row)">
                                新增商铺
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <layer-add-business-district class="layer-add-business-district" ref="layerAddBusinessDistrict"/>

        <layer-add-shops ref="layerAddShops"/>
    </section>
</template>

<script>
    import { tableColumn } from "./data";
    import cityCode from "@/assets/json/city_code.json";
    import { getShopList } from '@/api/shops-list';

    export default {
        name: "shops-list",
        data() {
            return {
                tableColumn,
                formSearch: {
                    name: null,
                    province: null,
                    city: null,
                    district: null
                },
                provinceList: cityCode,
                cityList: [],
                areaList: [],
            };
        },
        components: {
            layerAddBusinessDistrict: () => import("./component/layer-add-business-district"),
            layerAddShops: () => import("./component/layer-add-shops")
        },
        mounted() {
        },
        methods: {
            getShopList(params) {
                return getShopList(params);
            },
            afterDataReq(e) {
                for (let data of e) {
                    let {apartments} = data;
                    data.children = apartments;
                }
            },
            handleSearch() {
                // 搜索
                this.$refs['tableRef'].getTableData();
            },
            handleReset() {
                // 重置
                // this.formSearch.keyword = "";
                this.$refs["searchForm"].resetFields();
                this.cityList = [];
                this.areaList = [];
                this.handleSearch();
            },
            addEditShops(addEdit = "add", row) {
                this.$refs['layerAddShops'].openDialog(addEdit, row);
            },
            addEditBusinessDistrict(addEdit = "add", row) {
                this.$refs["layerAddBusinessDistrict"].openDialog(addEdit, row);
            },
            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formSearch.city = null;
                this.formSearch.district = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formSearch.district = null;
                this.areaList = area;
            },
            editHandle(type, data) {
                let {children} = data;
                if (children) this.addEditBusinessDistrict(type, data);
                else this.addEditShops(type, data);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shops-list {
        padding: 15px;

        .form-search-container {
            padding-top: VH(20px);
            padding-left: VW(15px);
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                    width: VW(250px);
                }
            }
        }
    }
</style>