import request from '@http';

// 2022/07/13 王江毅 合同列表查询
export function getShopContractPage(params) {
    return request({
        method: 'GET',
        url: '/api/shop/contract/pc/contract/page',
        params
    });
}

// 2022/07/13 王江毅  审核合同接口
export function contractAudit(uuid) {
    return request({
        method: 'POST',
        url: `/api/shop/contract/pc/audit/contract/${uuid}`
    });
}

// 2022/07/13 王江毅  根据房间查合同
export function getContractApartment(params) {
    return request({
        method: 'GET',
        url: `/api/shop/contract/pc/contract/apartment`,
        params
    });
}
