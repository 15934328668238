import {timeFormat, numberFormat,} from "@custom/index";

export function tableColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 100
        },
        {
            prop: "contractPcNumber",
            label: "合同编号",
        },
        {
            prop: "apartment.communityName",
            label: "所属商圈",
        },
        {
            prop: "apartment.apartmentName",
            label: "座落",
        },
        {
            label: "建筑面积",
            width: 80,
            render: (h, {row}) => {
                const {apartment:{outArea}} = row;
                return (parseFloat(outArea||0)).toFixed(2);
            }
        },
        {
            label: "使用面积",
            width: 80,
            render: (h, {row}) => {
                const {apartment:{useArea}} = row;
                return (parseFloat(useArea||0)).toFixed(2);
            }
        },
        {
            label: "合同开始时间",
            width: 100,
            render: (h, {row}) => {
                const {startDate} = row;
                return startDate ? timeFormat(new Date(startDate)) : '';
            }
        },
        {
            label: "合同结束时间",
            width: 100,
            render: (h, {row}) => {
                const {endDate} = row;
                return endDate ? timeFormat(new Date(endDate)) : '';
            }
        },
        {
            label: "保障金",
            width: 100,
            render: (h, {row}) => {
                const {payBill: {totalAmont}} = row;
                return numberFormat(totalAmont / 100);
            }
        },
        {
            prop: "createBillTypeName",
            label: "账单模式",
            width: 80,
        },
        {
            prop: "leasor.leasorType",
            label: "租户类型",
            width: 80,
        },
        {
            prop: "leasor.leasorName",
            label: "租户名称",
            width: 80,
        },
        {
            label: "合同签订时间",
            width: 100,
            render: (h, {row}) => {
                const {signDate} = row;
                return signDate ? timeFormat(new Date(signDate)) : '';
            }
        },
        {
            label: "合同状态",
            width: 100,
            render: (h, {row}) => {
                const {contractStatusName} = row;
                return contractStatusName || '';
            }
        },
    ];
}
