import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    {
        prop: "menuName",
        label: "菜单名"
    },
    {
        prop: "path",
        label: "菜单路径/权限"
    },
    {
        prop: "menuType",
        label: "类型",
        render: (h, {row}) => {
            const {menuType} = row;
            let menuTypeName = '';
            if (menuType === "M") menuTypeName = "菜单权限";
            if (menuType === "F") menuTypeName = "按钮权限";
            if (menuType === "C") menuTypeName = "界面权限";
            return h("span", menuTypeName);
        }
    },
];