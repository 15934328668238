<template>
    <section class="shops-release">
        <div class="top-bar bg-white">
            <el-form ref="searchForm" :model="formSearch" inline size="small" :rules="{}">
                <el-form-item label="区域" prop="province">
                    <el-select v-model="formSearch.province" placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="city">
                    <el-select v-model="formSearch.city" placeholder="请选择市" @change="cityChange">
                        <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="district">
                    <el-select v-model="formSearch.district" placeholder="请选择区">
                        <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item prop="keyword">
                    <el-input v-model="formSearch.apartmentName" placeholder="请输入商圈名称"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getPublishPageTableData" :columns="tableColumn"
                   :query="formSearch" :height="820" @selection-change="handleSelectionChange">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                       <!-- <span class="table-btn">查看</span>
                        <span class="table-btn">修改</span>-->
                        <span class="table-btn" @click="publishUpDown(row)">上架/下架</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {getPublishPage,publishUpDown} from "@/api/shops-release";
    import {shopsReleaseColumn} from "./data";
    import cityCode from "@/assets/json/city_code.json";
    import {MessageSuccess, MessageWarning, MessageError} from "@custom/message";
    export default {
        name: "shops-release",
        data() {
            return {
                tableColumn: shopsReleaseColumn,
                formSearch: {
                    publishStatus: 1,
                    province:null,
                    city: null,
                    area: null,
                    keyword: null,
                },
                multipleSelection: [],
                provinceList: cityCode,
                cityList: [],
                areaList: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: { },
        async created() {
        },
        mounted() {
        },
        methods: {
            getPublishPageTableData(params) {
                return getPublishPage(params);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleSearch(){
                this.$refs['tableRef'].getTableData();
            },
            handleReset(){
                this.$refs["searchForm"].resetFields();
                this.cityList = [];
                this.areaList = [];
                this.handleSearch();
            },
            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formSearch.city = null;
                this.formSearch.district = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formSearch.district = null;
                this.areaList = area;
            },
            publishUpDown(row){
                let that = this;
                let {uuid} = row;
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                publishUpDown(uuid).then(res => {
                    let {msg} =res;
                    that.handleSearch();
                    MessageSuccess(msg);
                    loading.close();
                }).catch(err => loading.close());
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shops-release {
        padding: 0 VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(15px);
            padding: VH(15px) VW(15px);
            justify-content: left;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
        /deep/ .el-form-item{
            margin-bottom: 0;
        }
    }
</style>