import request from '@http';

// 2022/06/15 王江毅 角色列表
export function getRoleList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/system/role/list`,
        params
    })
}

// 2022/06/15 王江毅 新增角色
export function roleSave(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/system/role/save`,
        data
    })
}

// 2022/06/15 王江毅 修改角色
export function roleUpdate(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/system/role/update`,
        data
    })
}

// 2022/06/15 王江毅 角色列表不分页
export function getRoleListNopage(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/system/role/list/nopage`,
        params
    })
}