<template>
    <section class="menu-management padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.name" placeholder="请输入菜单名搜索" style="margin-right: 10px;width: 250px" size="small"/>
            <span style="margin-right: 10px;">
                <el-radio-group v-model="formSearch.status" size="small">
                    <el-radio-button :label="true">有效</el-radio-button>
                    <el-radio-button :label="false">无效</el-radio-button>
                </el-radio-group>
            </span>
            <span style="margin-right: 10px;">
                <el-radio-group v-model="formSearch.visible" size="small">
                    <el-radio-button :label="true">显示</el-radio-button>
                    <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
            </span>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="saveMenu('add', 'M',null,null)" size="small">
                新增菜单
            </el-button>
        </div>
        <r-e-table class="bg-white" ref="menuTableRef" :data="tableData" :columns="tableColumn" :query="formSearch"
                   :show-pagination="false" :height="840">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="saveMenu('edit', row.menuType,row.parentUuid,row)">编辑</span>
                        <span v-if="row.menuType!=='F'&&row.path!=='/home'" class="table-btn"
                              @click="saveMenu('add',row.menuType==='M'?'C':'F',row.uuid,null)">新增</span>

                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <dialog-save-menu ref="dialogSaveMenu" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {getMenuTree} from "@/api/menu";
    import {tableColumn} from "@/views/system-management/menu-management/data";

    export default {
        name: "menu-management",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {}
            };
        },
        components: {
            dialogSaveMenu: () => import("./components/dialog-save-menu"),
        },
        mounted() {
            this.getMenuTree();
        },
        methods: {
            handleSearch() {
                this.getMenuTree();
            },
            handleReset() {
                this.formSearch = {name: ''};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            getMenuTree() {
                let that = this;
                this.$refs['menuTableRef'].loading = true;
                getMenuTree(this.formSearch).then(res => {
                    let {list} = res;
                    that.tableData = list;
                    this.$refs['menuTableRef'].pNumber = 1;
                    this.$refs['menuTableRef'].total = list.length;
                    this.$refs['menuTableRef'].loading = false;
                });
            },
            saveMenu(type, menuType, parentUuid, data) {
                this.$refs["dialogSaveMenu"].openDialog(type, menuType, parentUuid, data);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-management {

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: left;

            .addDictionary {
                position: absolute;
                left: VW(40px);
            }
        }
    }
</style>