<template>
    <section class="log-management padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 9px 0 7px; width: 250px" size="small"></el-input>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLogs" :columns="tableColumn" :query="formSearch" :height="830">
            <template slot="empty">
                <el-empty></el-empty>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import { tableColumn } from "./data";
    import { getLogs } from "@/api/log";
    export default {
        name: "log-management",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {},
            };
        },
        components:{
        },
        mounted() {

        },
        methods: {
            getLogs(params) {
                return getLogs(params);
            },
            handleSearch() {
                this.$refs.orderTableRef.pNumber = 1;
                this.$refs.orderTableRef.getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            }
        }
    }
</script>

<style lang="scss" scoped>
    .log-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>