import {timeFormat,numberFormat} from "@custom";

export function tableColumn (_this) {
    return [
        {
            label: "序号",
            type: "index",
            width: 100
        },
        {
            prop: "username",
            label: "账号"
        },
        {
            prop: "fullname",
            label: "姓名"
        },
        {
            prop: "mobile",
            label: "手机号"
        },
        {
            prop: "",
            label: "所负责小区"
        },
        {
            prop: "",
            label: "角色"
        }
    ];
}
