<template>
    <section class="contract-management">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-input size="small" style="width: 150px;margin-right: 10px;" v-model="formSearch.contractPcNum"
                      placeholder="请输入合同编号"/>
            <el-input size="small" style="width: 150px;margin-right: 10px;" v-model="formSearch.apartmentName"
                      placeholder="请输入房源名称"/>
            <el-date-picker v-model="formSearch.startDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="合同开始时间"
                            size="small"/>
            <el-date-picker v-model="formSearch.endDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="合同结束时间"
                            style="margin: 0 5px" size="small"/>
            <el-select v-model="formSearch.contractStatus" placeholder="请选择合同状态" style="margin-right: 10px" size="small">
                <el-option label="全部" :value="null"/>
                <el-option label="待完善" :value="0"/>
                <el-option label="待生效" :value="1"/>
                <el-option label="进行中" :value="2"/>
                <el-option label="已结束" :value="3"/>
                <el-option label="作废" :value="4"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="handleContractExport">导出数据</el-button>
        </div>
        <r-e-table class="bg-white" ref="contractTableRef" :dataRequest="getContractTableData" :columns="tableColumn"
                   :query="formSearch" :height="760">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row,row:{contractStatus} }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="contractStatus===1" @click="shenHe(row)">审核</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <layer-shenhe ref="layerShenhe" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {tableColumn} from "./data";
    import {getShopContractPage,contractAudit} from "@/api/shops-contract";
    import {downloadByData} from "@/utils/hooks/download";
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "contract-management",
        data() {
            return {
                tableColumn: tableColumn(this),
                formSearch: {
                    apartmentName: null,
                    contractStatus: null,
                    startDate: null,
                    endDate: null,
                    contractPcNum: null,
                },
                loadingOptions: {
                    lock: true,
                    text: "请您稍后...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components:{
            layerShenhe:()=>import("./components/layer-shenhe")
        },
        methods: {
            getContractTableData(params) {
                return getShopContractPage(params);
            },
            handleSearch() {
                this.$refs['contractTableRef'].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },

            handleContractExport() {
                /*const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let pageSize = this.$refs["contractTableRef"].pSize;
                let pageNumber = this.$refs["contractTableRef"].pNumber;
                let {dt1, dt2} = this.formSearch;
                dt1 = dt1 ? dt1 : "";
                dt1 = dt2 ? dt2 : "";
                let paramData = {dt1, dt2, pageSize, page: pageNumber};
                contractExport(paramData).then(res => {
                    downloadByData({data: res, filename: "合同.xlsx"});
                    loading.close();
                }).catch(err => {
                    loading.close();
                });*/
            },

            shenHe(row) {
                this.$refs["layerShenhe"].openDialog(row);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .contract-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>