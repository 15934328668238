import { timeFormat, numberFormat } from "@custom/index";

export const shopsReleaseColumn = [
    {
        type: "selection",
        width: 55,
    },
    {
        type: "index",
        label: "序号",
        width: 80,
    },
    {
        prop: "title",
        label: "房源标题",
    },
    {
        prop: "shopApartmentVo.communtiyName",
        label: "所属商圈",
        width: 100
    },
    {
        prop: "shopApartmentVo.apartmentNo",
        label: "商铺编号"
    },
    {
        label: "发布时间",
        render: (h, {row}) => {
            return row.publishTime ? timeFormat(new Date(row.publishTime)) : "";
        }
    },
    {
        prop: "statusName",
        label: "状态",
    },
    {
        label: "金额",
        render: (h, {row}) => {
            return numberFormat(row.rentalAmount/100);
        }
    },
    {
        prop: "totalCheckTheNumber",
        label: "访问次数",
    }
];