import request from '@http';

// 2022/07/15 王江毅 发布列表查询
export function getPublishPage(params) {
    return request({
        method: 'GET',
        url: '/api/shop/apartment/publish/page',
        params
    });
}

// 2022/07/15 王江毅 上下架发布信息
export function publishUpDown(uuid) {
    return request({
        method: 'post',
        url: `/api/shop/apartment/publish/upDown/${uuid}`
    });
}