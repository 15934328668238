// 建筑类型
export const architectureType = [
    {
        value: 1,
        label: "小区"
    },
    {
        value: 2,
        label: "楼幢"
    },
    {
        value: 3,
        label: "单元"
    }
];

// 小区管理 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 80
    },
    {
        label: "商圈/商铺名称",
        width: 300,
        className: 'column-tree',
        render: (h, {row}) => {
            const {name, shopOrApartment} = row;
            if (shopOrApartment === 0) {
                return [
                    /*h("img",
                        {
                            attrs: {
                                src: require("@/components/Gloabl/r-e-tree/img/icon-community.png")
                            },
                            style:{
                                width: "16px",
                                height: "16px",
                                marginRight: '5px',
                                verticalAlign: 'middle',
                            }
                        },
                    ),*/
                    h("i",
                        {
                            class: 'el-icon-office-building',
                            style: {
                                fontSize: '16px',
                                marginRight: '5px',
                                color: 'red',
                            }
                        },
                    ),
                    h("span", name)
                ];
            } else {
                return [
                    /*h("i",
                        {
                            attrs: {
                                src: require("@/components/Gloabl/r-e-tree/img/icon-house.png")
                            },
                            style:{
                                width: "16px",
                                height: "16px",
                                marginRight: '5px',
                                verticalAlign: 'middle',
                            }
                        },
                    ),*/
                    h("i",
                        {
                            class: 'el-icon-s-shop',
                            style: {
                                fontSize: '16px',
                                marginRight: '5px',
                                color: 'blue',
                            }
                        },
                    ),
                    h("span", name)
                ];
            }
        }
    },
    {
        label: "区域",
        render: (h, {row}) => {
            const {province, city, district} = row;
            return h("span", province + city + district);
        }
    },
    {
        label: "建筑面积",
        render: (h, {row}) => {
            let {outsideArea} = row;
            return h("span", outsideArea);
        }
    },
    {
        label: "使用面积",
        render: (h, {row}) => {
            let {insideArea} = row;
            return h("span", insideArea);
        }
    },
    {
        prop: "apartmentCount",
        label: "商铺总数",
        width: 100
    },
    {
        prop: "boundCount",
        label: "绑定商铺数",
        width: 100,
        /*render: (h, {row}) => {
            let {children} = row;
            if (children) return h("span", children.length);
            else return h("span", '0');
        }*/
    },
    {
        prop: "rentalApartmentCount",
        label: "已租商铺数",
        width: 100
    },
    {
        prop: "leisureApartmentCount",
        label: "空闲商铺总数",
        width: 100
    },
];


// 新增、编辑小区的表单验证
export function formCommunityRules() {
    return {
        name: [
            {required: true, message: "请输入小区名称", trigger: "blur"},
        ],
        houseManageName: [
            {required: true, message: '请选择房管员姓名', trigger: 'blur'},
        ],
        houseManagePhone: [
            {required: true, message: '请输入房管员手机号码', trigger: 'blur'},
        ],
    };
}

// 新增、编辑楼幢、单元的表单验证
export function formBuildingRules() {
    return {
        number: [
            {required: true, message: "请输入编号", trigger: "blur"},
        ],
    };
}
