export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 100
    },
    {
        prop: "code",
        label: "编码",
        sortable: true,
        className: "column-tree",
    },
    {
        prop: "codeName",
        label: "编码名称",
    },
    {
        prop: "comment",
        label: "备注"
    },
    {
        prop: "dataValue",
        label: "数据值",
    },
];


// 新增、编辑字典的表单验证
export function formDictionaryRules() {
    return {
        code: [
            { required: true, message: "请输入编码", trigger: "blur" },
        ],
        codeName: [
            { required: true, message: "请输入编码名称", trigger: "blur" },
        ],
    };
}
