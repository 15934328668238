import {timeFormat,numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    /*{
        prop: "businessModule",
        label: "操作模块",
    },*/
    {
        prop: "username",
        label: "操作人"
    },
    {
        prop: "timestamp",
        label: "时间",
        render: (h, {row}) => {
            const timeStamp = !row.timestamp ? "" : timeFormat(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }
    },
    {
        prop: "operation",
        label: "操作"
    },
    {
        prop: "recordType",
        label: "记录类型"
    },
    {
        prop: "record",
        label: "操作记录"
    }
];

